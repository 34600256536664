import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../AuthContext";
import { setLocalStorageItem, getLocalStorageItem } from "../helpers/helpers";
import "./login.css";
import { get } from "../helpers/api";

const Login = () => {
  const { login, getInfo } = useAuth();
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [info, setInfo] = useState({
    name: "One Track Solution",
    color: "#368ef1",
    host: "",
    logo: process.env.REACT_APP_API_BASE_URL + "/images/onedoor_logo.png",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const isMounted = useRef(true);

  useEffect(() => {
    const user = getLocalStorageItem("user");
    if (user) {
      // User is logged in, redirect to dashboard
      window.location.href = "/";
    }
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    const initializeInfo = async () => {
      try {
        setLoading(true);
        const result = await getInfo();
        // Check if result.data is defined before accessing its properties
        if (result.data) {
          setInfo(result.data);
          setLocalStorageItem("info", result.data);
          // Dynamically set background color based on the API response
          const container = document.getElementById("toggle");
          container.style.backgroundColor = result.data.color || "#368ef1";
          const loginBtn = document.getElementById("loginBtn");
          loginBtn.style.backgroundColor = result.data.color || "#368ef1";
          document.title = `${result.data.name} - Vehicle Tracking Services`;
        } else {
          console.error("Login error: Invalid response structure");
        }
      } catch (error) {
        console.error("Login error:", error);
      } finally {
        setLoading(false);
      }
    };

    initializeInfo();

    return () => {
      // Component is unmounting, update the flag
      isMounted.current = false;
    };
  }, [getInfo]); // Specify getInfo as a dependency

  const handleInputChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!credentials.email || !credentials.password) {
      setError("Both email and password are required");
      return;
    }
    try {
      setLoading(true);
      const result = await login(credentials);
      if (result) {
        const userinfo = await get("/api/user");
        setLocalStorageItem("user", userinfo.data);
        setLocalStorageItem("info", userinfo.data.company_info);
        setTimeout(() => {
          // navigate('/');
          window.location.href = localStorage.getItem("last_page") || "/";
        });
      }
    } catch (error) {
      setError(error.message);
      setLoading(false); // Reset loading state on error
    } finally {
      if (isMounted.current) {
        setLoading(false); // Ensure loading state is reset
      }
    }
  };

  useEffect(() => {
    const container = document.getElementById("login-container");
    const registerBtn = document.getElementById("register");
    const loginBtn = document.getElementById("login");

    if (registerBtn) {
      registerBtn.addEventListener("click", () => {
        container.classList.add("active");
      });
    }

    if (loginBtn) {
      loginBtn.addEventListener("click", () => {
        container.classList.remove("active");
      });
    }

    return () => {
      if (registerBtn) {
        registerBtn.removeEventListener("click");
      }

      if (loginBtn) {
        loginBtn.removeEventListener("click");
      }
    };
  }, []);

  return (
    <div className="login-body">
      {error && (
        <div className="alert alert-danger" style={{ fontSize: "14px" }}>
          {error}
        </div>
      )}
      <div className="login-container" id="login-container">
        <div className="form-container sign-in">
          <form onSubmit={handleLogin}>
            <div className="row justify-content-center mb-3">
              <div className="col-md-8 text-ce">
                <img
                  src={info.profile_image}
                  className="img-fluid"
                  alt="Company Logo"
                />
              </div>
            </div>

            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              onChange={handleInputChange}
              required
            />
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              onChange={handleInputChange}
              required
            />
            {/* <a href="#">Forget Your Password?</a> */}
            <button type="submit" id="loginBtn" disabled={loading}>
              {loading ? "Logging in..." : "Login"}
            </button>
          </form>
        </div>
        <div
          className="toggle-container d-none d-sm-block"
          style={{ backgroundColor: "#ffff" }}
        >
          <div className="toggle" id="toggle">
            <div className="toggle-panel toggle-right">
              <h1>
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Sign In
                </span>
                <br />
                {info.name}!
              </h1>
              <p>Easy tracking with us.</p>
              {/* <button className="hidden" id="register">Sign Up</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
