// FormField.js
import React from 'react';
import Select from 'react-select';

export const FormField = ({
    col,
    label,
    type,
    id,
    name,
    placeholder,
    value,
    onChange,
    onKeyDown,
    required,
    options,
    errorMessage,
    autoComplete,
    length,
    previewLink,
    accept,
    disabled,
    classes = '',
    fileName,
    min,
    pattern='',
    step="any"
}) => {
    switch (type) {
        case 'checkbox':
            return (
                <CheckboxField
                    col={col}
                    label={label}
                    id={id ? id : name}
                    name={name}
                    value={value ? value : ''}
                    onChange={onChange}
                    required={required}
                    errorMessage={errorMessage}
                    previewLink={previewLink}
                />
            );
        case 'select':
            return (
                <SelectField
                    col={col}
                    label={label}
                    id={id ? id : name}
                    name={name}
                    value={value ? value : ''}
                    onChange={onChange}
                    required={required}
                    options={options}
                    errorMessage={errorMessage}
                    previewLink={previewLink}

                />
            );
        case 'multi-select':
            return <MultiSelect
                classes={classes}
                col={col}
                label={label}
                id={id ? id : name}
                name={name}
                value={value ? value : ''}
                onChange={onChange}
                required={required}
                options={options}
                errorMessage={errorMessage}
            />
        case 'searchable-select':
            return <SearchableSelect
                classes={classes}
                col={col}
                label={label}
                id={id ? id : name}
                name={name}
                value={value ? value : ''}
                onChange={onChange}
                required={required}
                options={options}
                errorMessage={errorMessage}
                placeholder={placeholder}
            />

        default:
            return (
                <TextField
                    col={col}
                    label={label}
                    type={type}
                    id={id ? id : name}
                    name={name}
                    placeholder={placeholder ? placeholder : label}
                    value={value !== undefined && value !== null ? value : ''}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    required={required}
                    errorMessage={errorMessage}
                    autoComplete={autoComplete}
                    length={length}
                    previewLink={previewLink}
                    accept={accept}
                    disabled={disabled || ''}
                    fileName={fileName} // Add this line
                    min={min}
                    step={step}
                    pattern={pattern}
                />
            );
    }
};

// TextField.js
export const TextField = ({
    col,
    label,
    type,
    id,
    name,
    placeholder,
    value,
    onChange,
    onKeyDown,
    required,
    rows,
    errorMessage,
    autoComplete,
    length,
    previewLink,
    accept = 'images/*',
    disabled = false,
    fileName,
    min,
    step,
    pattern
}) => {
    return (
        <div className={col}>
            <div className='field'>
                <Label id={id} label={label} previewLink={previewLink} required={required} key={id} />
                {type === 'textarea' ? (
                    <textarea
                        className={`form-control form-control-solid ${errorMessage ? 'has-validation' : ''}`}
                        id={id}
                        name={name}
                        placeholder={placeholder ? placeholder : label}
                        value={value}
                        onChange={onChange}
                        required={required}
                        rows={rows}
                        length={length ? length : null}
                    />
                ) : (
                    <input
                        type={type}
                        className={`form-control form-control-solid ${errorMessage ? 'has-validation' : ''}`}
                        id={id ? id : name}
                        name={name}
                        placeholder={placeholder ? placeholder : label}
                        value={value}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        required={required}
                        length={length ? length : null}
                        accept={accept !== undefined && type === 'file' ? accept : ''}
                        autoComplete={autoComplete}
                        readOnly={type === 'email' || type === 'password'} // Add readonly attribute for email and password
                        onFocus={(e) => {
                            if (type === 'email' || type === 'password') {
                                e.target.removeAttribute('readonly');
                            }
                        }}
                        disabled={disabled || false}
                        min={min}
                        // Conditionally attach the step attribute
                        {...(step ? { step: step } : {})}
                        // Conditionally attach the pattern attribute
                        {...(pattern ? { pattern: pattern } : {})}
                    />
                )}
                {type === 'file' && fileName && <p>Selected file: {fileName}</p>}
                {errorMessage && <div className="invalid-feedback" style={{ display: 'block' }}>{errorMessage}</div>}
            </div>
        </div>
    );
};


// CheckboxField.js
export const CheckboxField = ({
    col,
    label,
    id,
    name,
    value,
    onChange,
    required,
    errorMessage,
    previewLink
}) => {
    return (
        <div className={col}>
            <div className='field'>
                <div className="form-check">
                    <input
                        type="checkbox"
                        className={`form-check-input ${errorMessage ? 'has-validation' : ''}`}
                        id={id ? id : name}
                        name={name}
                        checked={value}
                        onChange={onChange}
                        required={required}
                    />
                    <label className={`form-check-label ${required ? 'required' : ''}`} htmlFor={id}>
                        {label} {previewLink}
                    </label>
                </div>
                {errorMessage && <div className="invalid-feedback" style={{ display: 'block' }}>{errorMessage}</div>}
            </div>
        </div>
    );
};

// SelectField.js
export const SelectField = ({
    col,
    label,
    id,
    name,
    value,
    onChange,
    required,
    options,
    errorMessage,
    previewLink
}) => {
    return (
        <div className={col}>
            <div className='field'>
                <Label id={id} label={label} previewLink={previewLink} required={required} key={id} />
                <select
                    className={`form-control form-select  form-control-solid ${errorMessage ? 'has-validation' : ''}`}
                    id={id ? id : name}
                    name={name}
                    value={value}
                    onChange={onChange}
                    required={required}
                >
                    <option value="" disabled>Select {label}</option>
                    {options.map((option) => (
                        <option key={option.value || option.id} value={option.value || option.id}>
                            {option.label || option.name || option.model}
                        </option>
                    ))}
                </select>
                {errorMessage && <div className="invalid-feedback" style={{ display: 'block' }}>{errorMessage}</div>}
            </div>
        </div>
    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted gray',
        color: state.isSelected ? 'white' : 'black',
        backgroundColor: state.isSelected ? '#007bff' : 'white',
        '&:hover': {
            backgroundColor: '#007bff',
            color: 'white'
        }
    })
};

export const MultiSelect = ({
    col,
    label,
    id,
    name,
    value,
    onChange,
    required,
    options,
    errorMessage,
    classes
}) => {

    return (
        <div className={col}>
            <div className='field'>
                <Label id={id} label={label} required={required} key={id} />
                {/* Use the groupedOptions array in the Select component */}
                <Select closeMenuOnSelect={true}
                    name={name}
                    className={`${classes} ${errorMessage ? 'has-validation' : ''}`}
                    classNamePrefix="select"
                    value={value}
                    onChange={onChange}
                    styles={customStyles}
                    isMulti
                    defaultValue={options[1]}
                    options={options}
                />
                {errorMessage && <div className="invalid-feedback" style={{ display: 'block' }}>{errorMessage}</div>}
            </div>
        </div>
    );
};

export const SearchableSelect = ({
    col,
    label,
    id,
    name,
    value,
    onChange,
    required,
    options,
    errorMessage,
    classes,
    placeholder
}) => {
    return (
        <div className={col}>

            <div className='field'>
                <Label id={id} label={label} required={required} key={id} />

                <Select closeMenuOnSelect={true}
                    name={name}
                    options={options}
                    className={`${classes} ${errorMessage ? 'has-validation' : ''}`}
                    classNamePrefix="select"
                    value={value}
                    onChange={onChange}
                    isClearable
                    placeholder={placeholder ? placeholder : label}
                />
                {errorMessage && <div className="invalid-feedback" style={{ display: 'block' }}>{errorMessage}</div>}
            </div>
        </div>
    );
};

export const ButtonField = ({ label, onClick, disabled }) => {
    return (
        <button
            type="button"
            className="btn btn-sm btn-primary btn-lg mb-3"
            onClick={onClick}
            disabled={disabled}
        >
            {label}
        </button>
    );
};

export const Label = ({ label, required, previewLink, id }) => {
    if (label) {
        return (
            <label htmlFor={id} className={`form-label ${required ? 'required' : ''}`}>
                {label} {previewLink && previewLink}
            </label>
        );
    }
};
